<template>
  <div>
    <header-akciya />
    <usloviya />
    <action-conditions />
    <our-advantages />
    <our-training />
    <footer-akciya />
  </div>
</template>

<script>
import HeaderAkciya from "../components/HeaderAkciya.vue";
import OurAdvantages from "../components/OurAdvantages.vue";
import ActionConditions from "../components/ActionConditions.vue";
import OurTraining from "../components/OurTraining.vue";
import FooterAkciya from "../components/FooterAkciya.vue";
import Usloviya from "../components/Usloviya.vue";
export default {
  name: "Akciya",
  components: {
    HeaderAkciya,
    Usloviya,
    OurAdvantages,
    ActionConditions,
    OurTraining,
    FooterAkciya,
  },
};
</script>

<style scoped></style>
