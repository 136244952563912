<template>
    <section class=" pt-120 pb-120">
        <div class="container">
            <div class="row mb-none-30 justify-content-center">
                <div class="col-lg-4 col-sm-6 mb-30">
                    <div class="work-card text-center">
                        <div class="work-card__icon">
                            <div class="inner"><img src="/assets/images/icon/play/5.png" alt="image"></div>
                        </div>
                        <div class="work-card__content">
                            <h3 class="work-card__title">Футбольный лагерь</h3>
                            <p>В летний период устраиваем учебно-тренировочные сборы, где «нагружаем» детей техническими приемами, проводим теоретические занятия.</p>
                        </div>
                    </div><!-- work-card end -->
                </div>
                <div class="col-lg-4 col-sm-6 mb-30">
                    <div class="work-card text-center">
                        <div class="work-card__icon">
                            <div class="inner"><img src="/assets/images/icon/play/6.png" alt="image"></div>
                        </div>
                        <div class="work-card__content">
                            <h3 class="work-card__title">Дорогу талантам</h3>
                            <p>Сотрудничаем с муниципальными футбольными школами. Если Ваш ребёнок перерос уровень нашей школы, мы найдем для него более сильную команду.</p>
                        </div>
                    </div><!-- work-card end -->
                </div>
                <div class="col-lg-4 col-sm-6 mb-30">
                    <div class="work-card text-center">
                        <div class="work-card__icon">
                            <div class="inner"><img src="/assets/images/icon/play/7.png" alt="image"></div>
                        </div>
                        <div class="work-card__content">
                            <h3 class="work-card__title">Cтаршие братья</h3>
                            <p>Совместные мероприятия с профессиональным клубом «Динамо» Ставрополь. Болеем за нашу команду, воспитываем в юных футболистах патриотические чувства.</p>
                        </div>
                    </div><!-- work-card end -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "HowItWork"
    }
</script>

<style scoped>

</style>