<template>
    <div>
        <header-panel/>
        <inner-hero/>
        <section class="pb-120 mt-minus-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="contest-wrapper">
                            <div class="contest-wrapper__header pt-90">
                                <h2 class="contest-wrapper__title">Регистрация на тренировку</h2>
                            </div>
                            <div class="contest-wrapper__body">

                                <div class="row contest-filter-wrapper justify-content-center mt-30 mb-30">
                                    <div class="col-lg-3 col-sm-8 mb-30">
                                        <form class="contest-search-form">
                                            <masked-input
                                                    ref="phone"
                                                    mask="\+\7 (111)111-11-11"
                                                    @input="phone = '7' + arguments[1]"
                                                    placeholder="Ваш номер ☎"
                                            />
                                            <button><i class="fas fa-search"></i></button>
                                        </form>
                                    </div>
                                </div><!-- row end -->

                                <ApolloQuery v-if="phone" :query="gql => gql`query  phoneBooking($phone: String!) { phoneBooking (phone: $phone) { id child { name } childStatus trainingId { name }}}`" :variables="{ phone }">
                                    <template slot-scope="{ result: { data }}">
                                        <div v-if="data" class="container">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <training-booking-item v-for="pb in data.phoneBooking" :key="pb.id" :item="pb" :name="pb.child.name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="container">
                                            <div class="row">
                                                <phone-not-found/>
                                            </div>
                                        </div>
                                    </template>
                                </ApolloQuery>
                            </div>
                        </div><!-- contest-wrapper end -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import HeaderPanel from "../components/Header";
    import InnerHero from "../components/InnerHero";
    import MaskedInput from "vue-masked-input";
    import TrainingBookingItem from "../components/TrainingBookingItem";
    import PhoneNotFound from "../components/PhoneNotFound";
    export default {
        name: "TrainingBooking",
        components: {PhoneNotFound, HeaderPanel, InnerHero, MaskedInput, TrainingBookingItem},
        data() {
            return {
                phone: "",
            };
        },
        mounted() {
            this.$nextTick(() => {
                this.$refs.phone.$el.focus()
            })
        }
    }
</script>

<style scoped>

</style>