<template>
    <section class="pb-120">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="section-header text-center">
                        <span class="section-sub-title">Общество с ограниченной ответственностью</span>
                        <h2 class="section-title">Детский футбольный клуб Динамчики</h2>
                    </div>
                </div>
            </div>
            <div class="row mb-none-30">
                <div class="col-lg-6 mb-30 wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.3s">
                    <div class="support-card">
                        <div class="support-card__thumb">
                            <img src="assets/images/icon/support/1.png" alt="image">
                        </div>
                        <div class="support-card__content">
                            <h3 class="support-card__title">Наши реквизиты</h3>
                            <p>ИНН  : 2634093143,
                                КПП  : 263401001<br/>
                                Счёт (₽): 40702810002500081005<br/>
                                Банк: ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"<br/>
                                БИК  : 044525999<br/>
                                Корр. счёт: 30101810845250000999</p>
                            <div class="btn-grp justify-content-xl-start mt-3">
                                <a href="tel:+79283200494" class="btn-border btn-sm text-capitalize">Звоните! <i class="fas fa-phone-alt"></i></a>
                            </div>
                        </div>
                    </div><!-- support-card end -->
                </div>
                <div class="col-lg-6 mb-30 wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.5s">
                    <div class="support-card">
                        <div class="support-card__thumb">
                            <img src="assets/images/icon/support/2.png" alt="image">
                        </div>
                        <div class="support-card__content">
                            <h3 class="support-card__title">Лицензия</h3>
                            <p>№ 4557 от 12.02.2016 на осуществление образовательной деятельности выдана Министерством образования и молодежной политики Ставропольского края </p>
                            <div class="btn-grp justify-content-xl-start mt-3">
                                <a href="assets/images/contest/license.jpg" class="video-btn" data-rel="lightcase:myCollection"><i class="fas fa-play"></i></a>
                            </div>
                        </div>
                    </div><!-- support-card end -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Support"
    }
</script>

<style scoped>

</style>