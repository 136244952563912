<template>
    <div>
    <header-panel/>
    <inner-hero/>
    <section class="latest-winner-section position-relative pt-120 pb-120">
        <div class="el-1"><img src="assets/images/elements/w-el-1.png" alt="image"></div>
        <div class="el-2"><img src="assets/images/elements/w-el-2.png" alt="image"></div>
        <div class="el-3"><img src="assets/images/elements/w-el-3.png" alt="image"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                    <div class="section-header text-center">
                        <h2 class="section-title">Наши тренеры</h2>
                        <p>Check your ticket number's to see if you are a Winner in the Dream Lottery.</p>
                    </div>
                </div>
            </div><!-- row end -->

            <div class="row wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                <div class="col-lg-12">
                    <ul class="nav nav-tabs winner-tab-nav" id="winnerTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="tretyakov-tab" data-toggle="tab" href="#tretyakov" role="tab" aria-controls="dream" aria-selected="true">
                                <div class="icon-thumb"><img src="assets/images/icon/winner-tab/1.png" alt="image"></div>
                                <span>Третьяков Д.М.</span>
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="filippov-tab" data-toggle="tab" href="#filippov" role="tab" aria-controls="bike" aria-selected="false">
                                <div class="icon-thumb"><img src="assets/images/icon/winner-tab/2.png" alt="image"></div>
                                <span>Филиппов С.В.</span>
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="zaharov-tab" data-toggle="tab" href="#zaharov" role="tab" aria-controls="watch" aria-selected="false">
                                <div class="icon-thumb"><img src="assets/images/icon/winner-tab/3.png" alt="image"></div>
                                <span>Захаров Д.А.</span>
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="ushakov-tab" data-toggle="tab" href="#ushakov" role="tab" aria-controls="laptop" aria-selected="false">
                                <div class="icon-thumb"><img src="assets/images/icon/winner-tab/4.png" alt="image"></div>
                                <span>Ушаков С.В.</span>
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="manuylo-tab" data-toggle="tab" href="#manuylo" role="tab" aria-controls="money" aria-selected="false">
                                <div class="icon-thumb"><img src="assets/images/icon/winner-tab/5.png" alt="image"></div>
                                <span>Мануйло Р.Е.</span>
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="prudnikov-tab" data-toggle="tab" href="#prudnikov" role="tab" aria-controls="money" aria-selected="false">
                                <div class="icon-thumb"><img src="assets/images/icon/winner-tab/6.png" alt="image"></div>
                                <span>Прудников М.Ю.</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content mt-50" id="winnerTabContent">
                        <div class="tab-pane fade active show" id="tretyakov" role="tabpanel" aria-labelledby="tretyakov-tab">
                            <div class="row mb-none-30">
                                <div class="col-lg-4">
                                    <div class="user-card">
                                        <div class="avatar-upload">
                                            <div class="obj-el"><img src="/assets/images/elements/team-obj.png" alt="image"></div>
                                            <div class="avatar-preview">
                                                <div id="tretyakovPreview" style="background-image: url(/assets/images/user/tretyakov.png);">
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="user-card__name">Третьяков</h3>
                                        <span class="user-card__id">Дмитрий Михайлович</span>
                                    </div><!-- user-card end -->
                                </div>
                                <div class="col-lg-8 mt-lg-0 mt-5">
                                    <div class="user-info-card">
                                        <div class="user-info-card__header">
                                            <h3 class="user-info-card__title">Биография</h3>
                                        </div>
                                        <ul class="user-info-card__list">
                                            <li>
                                                <p> Категория «C» тренера по футболу. Стаж работы 18 лет. Команды Д.М.Третьякова побеждали во всероссийских турнирах, Детской Лиге Чемпионов, чемпионатах ЮФО/СКФО. Сразу пять воспитанников Д.М.Третьякова 1994 г.р. выступало за юношескую сборную России. Проходил обучение в Нидерландах по программе Национальной академии футбола. В 2006 г. признан лучшим детским тренером России.</p>
                                            </li>
                                        </ul>
                                    </div><!-- user-info-card end -->
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="filippov" role="tabpanel" aria-labelledby="filippov-tab">
                            <div class="row mb-none-30">
                                <div class="col-lg-4">
                                    <div class="user-card">
                                        <div class="avatar-upload">
                                            <div class="obj-el"><img src="/assets/images/elements/team-obj.png" alt="image"></div>
                                            <div class="avatar-preview">
                                                <div id="filippovPreview" style="background-image: url(/assets/images/user/filippov.png);">
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="user-card__name">Филиппов</h3>
                                        <span class="user-card__id">Станислав Владимирович</span>
                                    </div><!-- user-card end -->
                                </div>
                                <div class="col-lg-8 mt-lg-0 mt-5">
                                    <div class="user-info-card">
                                        <div class="user-info-card__header">
                                            <h3 class="user-info-card__title">Биография</h3>
                                        </div>
                                        <ul class="user-info-card__list">
                                            <li>
                                                <p> Категория «C» тренера по футболу. Образование высшее спортивное, Ставропольское училище Олимпийского резерва. Категория «C» тренера по мини-футболу, выдана Ассоциацией мини-футбола России в 2018 г. Чемпион России в турнире «Кожаный мяч» в составе команды «Динамо» Ставрополь. Обладатель Кубка ЮФО России.</p>
                                            </li>
                                        </ul>
                                    </div><!-- user-info-card end -->
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="zaharov" role="tabpanel" aria-labelledby="zaharov-tab">
                            <div class="row mb-none-30">
                                <div class="col-lg-4">
                                    <div class="user-card">
                                        <div class="avatar-upload">
                                            <div class="obj-el"><img src="/assets/images/elements/team-obj.png" alt="image"></div>
                                            <div class="avatar-preview">
                                                <div id="zaharovPreview" style="background-image: url(/assets/images/user/zaharov.png);">
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="user-card__name">Захаров</h3>
                                        <span class="user-card__id">Денис Алексеевич</span>
                                    </div><!-- user-card end -->
                                </div>
                                <div class="col-lg-8 mt-lg-0 mt-5">
                                    <div class="user-info-card">
                                        <div class="user-info-card__header">
                                            <h3 class="user-info-card__title">Биография</h3>
                                        </div>
                                        <ul class="user-info-card__list">
                                            <li>
                                                <p> Категория «D» тренера по футболу. Тренер МБУ спортшколы по футболу г.Ставрополя.</p>
                                            </li>
                                        </ul>
                                    </div><!-- user-info-card end -->
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="ushakov" role="tabpanel" aria-labelledby="ushakov-tab">
                            <div class="row mb-none-30">
                                <div class="col-lg-4">
                                    <div class="user-card">
                                        <div class="avatar-upload">
                                            <div class="obj-el"><img src="/assets/images/elements/team-obj.png" alt="image"></div>
                                            <div class="avatar-preview">
                                                <div id="ushakovPreview" style="background-image: url(/assets/images/user/ushakov.png);">
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="user-card__name">Ушаков</h3>
                                        <span class="user-card__id">Сергей Викторович</span>
                                    </div><!-- user-card end -->
                                </div>
                                <div class="col-lg-8 mt-lg-0 mt-5">
                                    <div class="user-info-card">
                                        <div class="user-info-card__header">
                                            <h3 class="user-info-card__title">Биография</h3>
                                        </div>
                                        <ul class="user-info-card__list">
                                            <li>
                                                <p> Образование высшее спортивное. Кандидат в мастера спорта. 509 матчей в чемпионатах и кубках страны за «Динамо» Ставрополь. Выступал также за московское «Динамо», алма-атинский «Кайрат», «Венец» из Гулькевичей, «Жемчужину» из Будённовска.</p>
                                            </li>
                                        </ul>
                                    </div><!-- user-info-card end -->
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="manuylo" role="tabpanel" aria-labelledby="manuylo-tab">
                            <div class="row mb-none-30">
                                <div class="col-lg-4">
                                    <div class="user-card">
                                        <div class="avatar-upload">
                                            <div class="obj-el"><img src="/assets/images/elements/team-obj.png" alt="image"></div>
                                            <div class="avatar-preview">
                                                <div id="manuyloPreview" style="background-image: url(/assets/images/user/manuylo.png);">
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="user-card__name">Мануйло</h3>
                                        <span class="user-card__id">Роман Евгеньевич</span>
                                    </div><!-- user-card end -->
                                </div>
                                <div class="col-lg-8 mt-lg-0 mt-5">
                                    <div class="user-info-card">
                                        <div class="user-info-card__header">
                                            <h3 class="user-info-card__title">Биография</h3>
                                        </div>
                                        <ul class="user-info-card__list">
                                            <li>
                                                <p> Образование высшее спартивное. </p>
                                            </li>
                                        </ul>
                                    </div><!-- user-info-card end -->
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="prudnikov" role="tabpanel" aria-labelledby="prudnikov-tab">
                            <div class="row mb-none-30">
                                <div class="col-lg-4">
                                    <div class="user-card">
                                        <div class="avatar-upload">
                                            <div class="obj-el"><img src="/assets/images/elements/team-obj.png" alt="image"></div>
                                            <div class="avatar-preview">
                                                <div id="prudnikovPreview" style="background-image: url(/assets/images/user/prudnikov.png);">
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="user-card__name">Прудников</h3>
                                        <span class="user-card__id">Михаил Юрьевич</span>
                                    </div><!-- user-card end -->
                                </div>
                                <div class="col-lg-8 mt-lg-0 mt-5">
                                    <div class="user-info-card">
                                        <div class="user-info-card__header">
                                            <h3 class="user-info-card__title">Биография</h3>
                                        </div>
                                        <ul class="user-info-card__list">
                                            <li>
                                                <p> Образование высшее спортивное, Северо-Кавказский Федеральный университет. Категория «C» тренера по мини-футболу, выдана Ассоциацией мини-футбола России в 2018 г. 2-е место во Всероссийском турнире «Кожанный мяч». Чемпион, серебряный и бронзовый призёр чемпионата Ставропольского края, чемпион и вице-чемпион среди команд Вооруженных сил РФ.</p>
                                            </li>
                                        </ul>
                                    </div><!-- user-info-card end -->
                                </div>
                            </div>
                        </div>
                    </div><!-- tab-content end -->
                </div>
            </div><!-- row end -->
        </div>
    </section>
    </div>
</template>

<script>
    import HeaderPanel from "../components/Header";
    import InnerHero from "../components/InnerHero";
    export default {
        name: "Coaches",
        components: {HeaderPanel, InnerHero}
    }
</script>

<style scoped>

</style>