<template>
    <section class="position-relative pt-120 pb-120 overflow-hidden">
        <div class="play-elements wow bounceIn" data-wow-duration="0.5s" data-wow-delay="0.7s">
            <img src="assets/images/elements/play-el.png" alt="image">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-sm-left text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                    <div class="section-header">
                        <span class="section-sub-title">Наши преимущества</span>
                        <h2 class="section-title">Работаем на будущее!</h2>
                        <p>Готовим дошкольников для поступления в детско-юношеские спортивные школы. Занимаемся со школьниками, которых сочли неперспективными в других нефутбольных и футбольных секциях</p>
                    </div>
                </div>
            </div>
            <div class="row mb-none-30 justify-content-xl-start justify-content-center">
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div class="play-card bg_img" data-background="assets/images/elements/card-bg-1.jpg">
                        <div class="play-card__icon">
                            <img src="assets/images/icon/play/1.png" alt="image-icon">
                            <span class="play-card__number">01</span>
                        </div>
                        <div class="play-card__content">
                            <h3 class="play-card__title">Индивидуальный подход</h3>
                            <p>Вашего ребёнка не оставят без присмотра, похвалят за старательность и корректно поправят в случае ошибок.</p>
                        </div>
                    </div><!-- play-card end -->
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div class="play-card bg_img" data-background="assets/images/elements/card-bg-2.jpg">
                        <div class="play-card__icon">
                            <img src="assets/images/icon/play/2.png" alt="image-icon">
                            <span class="play-card__number">02</span>
                        </div>
                        <div class="play-card__content">
                            <h3 class="play-card__title">Комфортные условия</h3>
                            <p>Занимаемся на футбольных полях с искусственным покрытием и в современных спортзалах</p>
                        </div>
                    </div><!-- play-card end -->
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div class="play-card bg_img" data-background="assets/images/elements/card-bg-3.jpg">
                        <div class="play-card__icon">
                            <img src="assets/images/icon/play/3.png" alt="image-icon">
                            <span class="play-card__number">03</span>
                        </div>
                        <div class="play-card__content">
                            <h3 class="play-card__title">Режим тренировок</h3>
                            <p>Для каждого возраста проводим по три тренировки каждую неделю 12 месяцев в году.</p>
                        </div>
                    </div><!-- play-card end -->
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div class="play-card bg_img" data-background="assets/images/elements/card-bg-3.jpg">
                        <div class="play-card__icon">
                            <img src="assets/images/icon/play/4.png" alt="image-icon">
                            <span class="play-card__number">04</span>
                        </div>
                        <div class="play-card__content">
                            <h3 class="play-card__title">Турниры</h3>
                            <p>Организуем футбольные праздники и турниры. Участвуем в соревнованиях любого уровня.</p>
                        </div>
                    </div><!-- play-card end -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "HowToPlay"
    }
</script>

<style scoped>

</style>