/* eslint-disable no-unused-vars */
import Vue from "vue";
import Router from "vue-router";
import Index from "./views/Index";
import Shop from "./views/Shop";
import Akciya from "./views/Akciya.vue";
import TrainingBooking from "./views/TrainingBooking";
import CoachBooking from "./views/CoachBooking";
import Coaches from "./views/Coaches";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "index",
      component: Index,
    },
    {
      path: "/akciya",
      name: "akciya",
      component: Akciya,
    },
    {
      path: "/akciya_dinamo",
      name: "akciya_dinamo",
      component: Akciya,
    },
    {
      path: "/shop",
      name: "shop",
      component: Shop,
    },
    {
      path: "/booking",
      name: "booking",
      component: TrainingBooking,
    },
    {
      path: "/coach-booking",
      name: "coachBooking",
      component: CoachBooking,
    },
    {
      path: "/coaches",
      name: "coaches",
      component: Coaches,
    },
  ],
});

export default router;
