import { render, staticRenderFns } from "./Usloviya.vue?vue&type=template&id=2992cc12&scoped=true"
import script from "./Usloviya.vue?vue&type=script&lang=js"
export * from "./Usloviya.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2992cc12",
  null
  
)

export default component.exports