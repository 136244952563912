<template>
    <div class="inner-hero-section style--three">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <ul class="page-list">
                        <li><router-link to="/">Главная</router-link></li>
                        <li class="active">Регистрация на тренировки</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InnerHero"
    }
</script>

<style scoped>

</style>