<template>
    <div class="col-lg-12">
        <div class="user-info-card">
            <div class="user-info-card__header">
                <h3 class="user-info-card__title">
                    Номер телефона не найден!
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PhoneNotFound"
    }
</script>

<style scoped>

</style>