import gql from "graphql-tag";

export const PLACES_ALL = gql`
    query places {
        places {
            name
            alias
            description
            address
            latitude
            longitude
            order
        }
    }
`;

export const PLACE_BY_ALIAS = gql`
    query placeByAlias($alias: String!) {
        placeByAlias(alias: $alias) {
            name
            alias
            description
            address
            latitude
            longitude
            order
        }
    }
`;

export const YOUTUBE_VIDEOS = gql`
    query videos {
        videos {
            id
            title
            description
            thumbnail
            publishedAt
        }
    }
`;

export const ARTICLES_ALL = gql`
    query articles {
        articles {
            title
            alias
            description
            tags
            author
            date
            fileName
        }
    }
`;

export const ARTICLE_BY_ALIAS = gql`
    query articleByAlias($alias: String!) {
        articleByAlias(alias: $alias) {
            title
            alias
            description
            tags
            author
            date
            fileName
        }
    }
`;

export const BIRTHDAYS = gql`
    query birthdays {
        birthdays {
            birthday
            name
            team {
                name
                alias
                place {
                    alias
                }
            }
        }
    }
`;

export const CHILDREN_BY_TEAM = gql`
    query childrenByTeam($team: String!) {
        childrenByTeam(team: $team) {
            birthday
            name
            team {
                name
                alias
                place {
                    alias
                }
            }
        }
    }
`;

export const COACHES = gql`
    query coaches {
        coaches {
            alias
            birthday
            department
            name
            work
            description
        }
    }
`;

export const COACH_BY_ALIAS = gql`
    query coachByAlias($alias: String!) {
        coachByAlias(alias: $alias) {
            alias
            birthday
            name
            questionAnswer {
                qaType
                question
                answer
            }
            work
            description
            tournamentAwards {
                name
                date
                age
                position
            }
        }
    }
`;

export const STAFF = gql`
    query staff {
        staff {
            alias
            department
            birthday
            fileName
            name
            work
        }
    }
`;

export const PHOTOALBUMS = gql`
    query photoalbums {
        photoAlbums {
            alias
            description
            source
            tags
            title
        }
    }
`;

export const PHOTOALBUM_BY_ALIAS = gql`
    query photoalbumByAlias($alias: String!) {
        photoAlbumByAlias(alias: $alias) {
            alias
            description
            source
            tags
            title
        }
    }
`;

export const PHOTOS_BY_ALBUM = gql`
    query photosByAlbum($album: String!) {
        photosByAlbum(album: $album) {
            album {
                alias
                title
            }
            fileName
            tags
        }
    }
`;

export const STADIUMS_BY_PLACE = gql`
    query stadiumsByPlace($place: String!) {
        stadiumsByPlace(place: $place) {
            alias
            capacity
            description
            name
            place {
                alias
                name
            }
        }
    }
`;

export const TEAMS_BY_PLACE = gql`
    query teamsByPlace($place: String!) {
        teamsByPlace(place: $place) {
            ages
            alias
            headCoach {
                name
                alias
            }
            name
            place {
                name
                alias
            }
            trainingDays {
                dayOfWeek
                time
                stadium {
                    name
                    alias
                }
            }
        }
    }
`;

export const TEAMS_ALL = gql`
    query teams {
        teams {
            ages
            alias
            headCoach {
                name
                alias
            }
            name
            place {
                name
                alias
            }
            trainingDays {
                dayOfWeek
                time
                stadium {
                    name
                    alias
                }
            }
        }
    }
`;

export const TRAININGS_BY_PLACE = gql`
    query trainingsByPlace($place: String!) {
        trainingsByPlace(place: $place) {
            stadium {
                name
                alias
            }
            team {
                name
                alias
            }
            time
        }
    }
`;

export const TRAININGS_BY_TEAM = gql`
    query trainingsByTeam($team: String!) {
        trainingsByTeam(team: $team) {
            stadium {
                name
                alias
            }
            team {
                name
                alias
            }
            time
        }
    }
`;

export const CASTING_SAVE = gql`
    mutation castingSave($input: CastingItemInput!) {
        castingSave(input: $input)
    }
`;

export const QUESTION_MESSAGE_SAVE = gql`
    mutation questionMessageSave($input: QuestionMessageItemInput!) {
        questionMessageSave(input: $input)
    }
`;

export const TRAINING_BOOKING = gql`
    mutation trainingBooking($input: TrainingBookingInput!) {
        trainingBooking(input: $input) {
            id
            visits
        }
    }
`;
