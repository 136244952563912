<template>
    <div>
        <preloader/>
        <scroll-to-top/>
        <div class="page-wrapper">
            <slot />
        </div>
    </div>
</template>

<script>
    import Preloader from "../components/Preloader";
    import ScrollToTop from "../components/ScrollToTop";
    export default {
        name: "Default",
        components: {Preloader, ScrollToTop}
    }
</script>

<style scoped>

</style>