<template>
    <section class="position-relative pt-120 pb-120" id="places">
        <div class="bg-el"><img src="assets/images/bg/contest-bg.jpg" alt="image"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                    <div class="section-header text-center">
                        <span class="section-sub-title">Наши филиалы</span>
                        <h2 class="section-title">В СТАВРОПОЛЕ</h2>
                        <p>Выберите место для тренировок рядом с домом!</p>
                    </div>
                </div>
            </div><!-- row end -->
            <div class="row wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                <div class="col-lg-12">
                    <div class="tab-content" id="contestTabContent">
                        <div class="tab-pane fade show active" id="car" role="tabpanel" aria-labelledby="car-tab">
                            <div class="row mb-none-30">
                                <div class="col-xl-6 col-md-6 mb-30">
                                    <div class="contest-card">
                                        <a href="#places" class="item-link"></a>
                                        <div class="contest-card__thumb">
                                            <img src="assets/images/contest/1.png" alt="image">
                                            <a href="#places" class="action-icon"><i class="far fa-heart"></i></a>
                                            <div class="contest-num">
                                                <h4 class="number">Юго-Запад</h4>
                                            </div>
                                        </div>
                                        <div class="contest-card__content">
                                            <div class="left">
                                                <h5 class="contest-card__name">ул.Тухачевского, 18/1</h5>
                                            </div>
                                            <div class="right">
                                                <span class="contest-card__price">3-14 лет</span>
                                                <p>поле, зал</p>
                                            </div>
                                        </div>
                                        <div class="contest-card__footer">
                                            <ul class="contest-card__meta">
                                                <li>
                                                    <i class="las la-clock"></i>
                                                    <span>пн,вт,ср,чт,пт,сб</span>
                                                </li>
                                                <li>
                                                    <i class="las la-ticket-alt"></i>
                                                    <span>3500-4000₽</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div><!-- contest-card end -->
                                </div>
                                <div class="col-xl-6 col-md-6 mb-30">
                                    <div class="contest-card">
                                        <a href="#places" class="item-link"></a>
                                        <div class="contest-card__thumb">
                                            <img src="assets/images/contest/2.png" alt="image">
                                            <a href="#places" class="action-icon"><i class="far fa-heart"></i></a>
                                            <div class="contest-num">
                                                <h4 class="number">204 квартал</h4>
                                            </div>
                                        </div>
                                        <div class="contest-card__content">
                                            <div class="left">
                                                <h5 class="contest-card__name">ул.Чехова, 19/4, ул.Серова, 533</h5>
                                            </div>
                                            <div class="right">
                                                <span class="contest-card__price">3-14 лет</span>
                                                <p>поле, зал</p>
                                            </div>
                                        </div>
                                        <div class="contest-card__footer">
                                            <ul class="contest-card__meta">
                                                <li>
                                                    <i class="las la-clock"></i>
                                                    <span>вт,чт,сб</span>
                                                </li>
                                                <li>
                                                    <i class="las la-ticket-alt"></i>
                                                    <span>3500-4000₽</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div><!-- contest-card end -->
                                </div>
                                <div class="col-xl-6 col-md-6 mb-30">
                                    <div class="contest-card">
                                        <a href="#places" class="item-link"></a>
                                        <div class="contest-card__thumb">
                                            <img src="assets/images/contest/7.png" alt="image">
                                            <a href="#places" class="action-icon"><i class="far fa-heart"></i></a>
                                            <div class="contest-num">
                                                <h4 class="number">Центр</h4>
                                            </div>
                                        </div>
                                        <div class="contest-card__content">
                                            <div class="left">
                                                <h5 class="contest-card__name">ул.Розы Люксембург, 58, пр.Октябрьской революции, 33а</h5>
                                            </div>
                                            <div class="right">
                                                <span class="contest-card__price">3-6 лет</span>
                                                <p>зал</p>
                                            </div>
                                        </div>
                                        <div class="contest-card__footer">
                                            <ul class="contest-card__meta">
                                                <li>
                                                    <i class="las la-clock"></i>
                                                    <span>пн,вт,ср,чт,пт,сб</span>
                                                </li>
                                                <li>
                                                    <i class="las la-ticket-alt"></i>
                                                    <span>3500-4000₽</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div><!-- contest-card end -->
                                </div>
                                <div class="col-xl-6 col-md-6 mb-30">
                                    <div class="contest-card">
                                        <a href="#places" class="item-link"></a>
                                        <div class="contest-card__thumb">
                                            <img src="assets/images/contest/8.png" alt="image">
                                            <a href="#0" class="action-icon"><i class="far fa-heart"></i></a>
                                            <div class="contest-num">
                                                <h4 class="number">Новый</h4>
                                            </div>
                                        </div>
                                        <div class="contest-card__content">
                                            <div class="left">
                                                <h5 class="contest-card__name">Скоро открытие</h5>
                                            </div>
                                            <div class="right">
                                                <span class="contest-card__price">3-14 лет</span>
                                                <p>зал</p>
                                            </div>
                                        </div>
                                        <div class="contest-card__footer">
                                            <ul class="contest-card__meta">
                                                <li>
                                                    <i class="las la-clock"></i>
                                                    <span>пн,вт,ср,чт,пт,сб</span>
                                                </li>
                                                <li>
                                                    <i class="las la-ticket-alt"></i>
                                                    <span>3500-4000₽</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div><!-- contest-card end -->
                                </div>
                            </div>
                        </div>
                    </div><!-- table content end -->
                </div>
            </div><!-- row end-->
        </div>
    </section>
</template>

<script>
    export default {
        name: "Contest"
    }
</script>

<style scoped>

</style>