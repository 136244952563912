<template>
    <div>
        <header-panel/>
        <hero/>
        <how-to-play/>
        <contest/>
        <how-it-work/>
        <testimonial/>
        <support/>
        <footer-panel/>
    </div>
</template>

<script>
    import HeaderPanel from "../components/Header";
    import Hero from "../components/Hero";
    import HowToPlay from "../components/HowToPlay";
    import Contest from "../components/Contest";
    import HowItWork from "../components/HowItWork";
    import Testimonial from "../components/Testimonial";
    import Support from "../components/Support";
    import FooterPanel from "../components/Footer";
    export default {
        name: "Index",
        components: {HeaderPanel, Hero, HowToPlay, Contest, HowItWork, Testimonial, Support, FooterPanel}
    }
</script>

<style scoped>

</style>