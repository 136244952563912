<template>
  <section class="position-relative pt-120 pb-120 overflow-hidden">
    <div
      class="play-elements wow bounceIn"
      data-wow-duration="0.5s"
      data-wow-delay="0.7s"
    >
      <img src="assets/images/elements/play-el.png" alt="image" />
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 text-sm-left text-center wow fadeInUp"
          data-wow-duration="0.5s"
          data-wow-delay="0.3s"
        >
          <div class="section-header">
            <span class="section-sub-title">Условия акции</span>
            <h2 class="section-title">Сумма начисления</h2>
            <p>
              Каждому участнику акции будет начислено 3000 баллов на счет из
              расчета 1 балл за 1 рубль
            </p>
          </div>
        </div>
      </div>
      <div
        class="row mb-none-30 justify-content-xl-start justify-content-center"
      >
        <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
          <div
            class="play-card bg_img"
            data-background="assets/images/elements/card-bg-1.jpg"
          >
            <div class="play-card__icon">
              <img src="assets/images/icon/play/1.png" alt="image-icon" />
              <span class="play-card__number">01</span>
            </div>
            <div class="play-card__content">
              <h3 class="play-card__title">Срок действия баллов</h3>
              <p>Баллы расходуются ежемесячно в размере 300 баллов.</p>
            </div>
          </div>
          <!-- play-card end -->
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
          <div
            class="play-card bg_img"
            data-background="assets/images/elements/card-bg-2.jpg"
          >
            <div class="play-card__icon">
              <img src="assets/images/icon/play/2.png" alt="image-icon" />
              <span class="play-card__number">02</span>
            </div>
            <div class="play-card__content">
              <h3 class="play-card__title">Льготные условия</h3>
              <p>Сертификатом можно воспользоваться до 18.09.2024</p>
            </div>
          </div>
          <!-- play-card end -->
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
          <div
            class="play-card bg_img"
            data-background="assets/images/elements/card-bg-3.jpg"
          >
            <div class="play-card__icon">
              <img src="assets/images/icon/play/3.png" alt="image-icon" />
              <span class="play-card__number">03</span>
            </div>
            <div class="play-card__content">
              <h3 class="play-card__title">Количество сертификатов</h3>
              <p>
                Вы можете приобрести неограниченное количество сертификатов.
              </p>
            </div>
          </div>
          <!-- play-card end -->
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
          <div
            class="play-card bg_img"
            data-background="assets/images/elements/card-bg-3.jpg"
          >
            <div class="play-card__icon">
              <img src="assets/images/icon/play/4.png" alt="image-icon" />
              <span class="play-card__number">04</span>
            </div>
            <div class="play-card__content">
              <h3 class="play-card__title">Сгорание баллов</h3>
              <p>Срок сгорания последних баллов - июнь 2025 года.</p>
            </div>
          </div>
          <!-- play-card end -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurAdvantages",
};
</script>

<style scoped></style>
