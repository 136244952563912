<template>
    <div class="scroll-to-top">
    <span class="scroll-icon">
      <i class="las la-arrow-up"></i>
    </span>
    </div>
</template>

<script>
    export default {
        name: "ScrollToTop"
    }
</script>

<style scoped>

</style>