<template>
  <div id="app">
    <component :is="layout">
      <router-view :key="componentKey" />
    </component>
  </div>
</template>

<script>
  const DefaultLayout = "default";

export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || DefaultLayout) + "-layout";
    },
    componentKey() {
      return this.$route.fullPath;
    },
  },
}
</script>

<style>

</style>
