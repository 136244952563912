<template>
    <footer class="footer-section">
        <div class="bg-shape--top"><img src="assets/images/elements/round-shape-2.png" alt="image"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">
                    <div class="subscribe-area" v-if="message">
                        <div class="left">
                            <span class="subtitle">{{message}}</span>
                        </div>
                    </div>
                    <div class="subscribe-area" v-else>
                        <div class="left">
                            <span class="subtitle">Записать ребёнка на</span>
                            <h3 class="title">бесплатную тренировку</h3>
                        </div>
                        <div class="right">
                            <form class="subscribe-form" id="free">
                                <input v-model="name" type="text" name="subscribe_name" id="subscribe_name" placeholder="Ваше имя">
                                <masked-input
                                        name="subscribe_phone"
                                        id="subscribe_phone"
                                        mask="\+\7 (111)111-11-11"
                                        @input="phone = arguments[1]"
                                        placeholder="Ваш номер ☎"
                                />
                                <button @click.prevent="castingDone" type="submit">Записать</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container pt-120">
            <div class="row py-5 align-items-center">
                <div class="col-lg-6">
                    <p class="copy-right-text text-lg-left text-center mb-lg-0 mb-3">Copyright © 2015-2024. <a href="index.html">ДФК Динамчики</a></p>
                </div>
                <div class="col-lg-6">
                    <ul class="social-links justify-content-lg-end justify-content-center">
                        <li><a href="https://instagram.com/dinamchiki"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/c/dinamchiki"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="https://vk.com/dinamchiki"><i class="fab fa-vk"></i></a></li>
                        <li><a href="https://t.me/dinamchiki"><i class="fab fa-telegram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import { CASTING_SAVE } from "../graphql/Queries";
    import MaskedInput from "vue-masked-input";

    export default {
        name: "Footer",
        components: {
            MaskedInput
        },
        data() {
            return {
                message: "",
                phone: "",
                name: "",
                yearBorn: null,
                place: null,
            };
        },
        computed: {
            phoneCheck: function () {
                return this.phone.match(/^[0-9]{10}$/)
            }
        },
        methods: {
            castingDone: function () {
                if (this.phone) {
                    const phone = 7 + this.phone;
                    const input = {
                        phone: phone,
                        name: this.name + ' homePage'
                    };
                    // eslint-disable-next-line no-undef
                    ym(65546623,'reachGoal','freeTraining', input);
                    // eslint-disable-next-line no-undef
                    fbq('track', 'Lead');
                    this.$apollo.mutate({
                        mutation: CASTING_SAVE,
                        variables: {
                            input: input,
                        },
                        update: (store, { data: { castingSave } }) => {
                            this.message = castingSave;
                        },
                    });
                    this.phone = "";
                    this.name = "";
                }
            },
        },
    }
</script>

<style scoped>

</style>