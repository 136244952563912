<template>
    <div>
        <header-panel/>
        <inner-hero/>
        <shop-items/>
    </div>
</template>

<script>
    import HeaderPanel from "../components/Header";
    import InnerHero from "../components/InnerHero";
    import ShopItems from "../components/ShopItems";
    export default {
        name: "Index",
        components: {ShopItems, HeaderPanel, InnerHero}
    }
</script>

<style scoped>

</style>