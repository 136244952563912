<template>
    <section class="position-relative pt-120 pb-120 overflow-hidden">
        <div class="play-elements wow bounceIn" data-wow-duration="0.5s" data-wow-delay="0.7s">
            <img src="assets/images/elements/play-el.png" alt="image">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-sm-left text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                    <div class="section-header">
                        <span class="section-sub-title">Наши преимущества</span>
                        <h2 class="section-title">Работаем на будущее!</h2>
                        <p>Мы не только учим футболу, но и способствуем развитию физических, социальных и лидерских качеств</p>
                    </div>
                </div>
            </div>
            <div class="row mb-none-30 justify-content-xl-start justify-content-center">
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div class="play-card bg_img" data-background="assets/images/elements/card-bg-1.jpg">
                        <div class="play-card__icon">
                            <img src="assets/images/icon/play/1.png" alt="image-icon">
                            <span class="play-card__number">01</span>
                        </div>
                        <div class="play-card__content">
                            <h3 class="play-card__title">Индивидуальный подход</h3>
                            <p>Мы учитываем способности и цели каждого игрока, разрабатывая персонализированные тренировочные программы.</p>
                        </div>
                    </div><!-- play-card end -->
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div class="play-card bg_img" data-background="assets/images/elements/card-bg-2.jpg">
                        <div class="play-card__icon">
                            <img src="assets/images/icon/play/2.png" alt="image-icon">
                            <span class="play-card__number">02</span>
                        </div>
                        <div class="play-card__content">
                            <h3 class="play-card__title">Современная инфраструктура</h3>
                            <p>Тренировки проходят на современных футбольных полях с качественным покрытием и безопасным оборудованием</p>
                        </div>
                    </div><!-- play-card end -->
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div class="play-card bg_img" data-background="assets/images/elements/card-bg-3.jpg">
                        <div class="play-card__icon">
                            <img src="assets/images/icon/play/3.png" alt="image-icon">
                            <span class="play-card__number">03</span>
                        </div>
                        <div class="play-card__content">
                            <h3 class="play-card__title">Доступные цены</h3>
                            <p>Мы предлагаем разумные цены и различные варианты оплаты, чтобы сделать занятия футболом доступными для всех.</p>
                        </div>
                    </div><!-- play-card end -->
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div class="play-card bg_img" data-background="assets/images/elements/card-bg-3.jpg">
                        <div class="play-card__icon">
                            <img src="assets/images/icon/play/4.png" alt="image-icon">
                            <span class="play-card__number">04</span>
                        </div>
                        <div class="play-card__content">
                            <h3 class="play-card__title">Участие в турнирах</h3>
                            <p>Наши команды регулярно участвуют в местных и региональных соревнованиях, что помогает ребятам получить ценный соревновательный опыт.</p>
                        </div>
                    </div><!-- play-card end -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "OurAdvantages"
    }
</script>

<style scoped>

</style>