<template>
    <section class="hero style--three">
        <div class="hero__circle"><img src="assets/images/elements/hero-4-circle.png" alt="image"></div>
        <div class="hero__obj"><img src="assets/images/elements/hero-4-obj.png" alt="image"></div>

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="hero__content text-center">
                        <div class="hero__subtitle wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.9s">Самая опытная в Ставрополе</div>
                        <h2 class="hero__title wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">лицензированная негосударственная футбольная школа</h2>
                        <p class="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">Набираем мальчиков и девочек от 3 лет для занятий по футболу</p>
                        <div class="hero__btn wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                            <a href="#free" class="cmn-btn">Запись в клуб</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Hero"
    }
</script>

<style scoped>

</style>