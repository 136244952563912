<template>
        <div class="user-info-card">
            <div class="user-info-card__header">
                <h3 class="user-info-card__title">
                    <span v-if="status === 'PRESENT'"><i class="fas fa-check"></i></span>
                    {{name}}
                </h3>
                <button type="button"  @click="changeStatus"><i class="far fa-edit"></i> Изменить</button>
            </div>
        </div>
</template>

<script>
    import { TRAINING_BOOKING } from "../graphql/Queries";
    export default {
        name: "TrainingBookingItem",
        props: ['item', 'name'],
        data() {
            return {
                status: this.item.childStatus
            }
        },
        methods: {
            changeStatus() {
                let status = this.status === 'PRESENT' ? 'ABSENT' : 'PRESENT'
                const input = {
                    childStatus: status,
                    id: this.item.id
                };
                this.$apollo.mutate({
                    mutation: TRAINING_BOOKING,
                    variables: {
                        input: input,
                    },
                    update: (store, { data: { trainingBooking } }) => {
                        console.log(trainingBooking)
                        this.status = status
                    },
                });
            }
        }
    }
</script>

<style scoped>

</style>