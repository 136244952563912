<template>
    <section class="has-bg--shape pt-120 pb-120">
        <div class="bg-shape">
            <div class="round-shape d-sm-block d-none"><img src="assets/images/elements/round-shape.png" alt="image"></div>
            <div class="shape-1"></div>
            <div class="shape-2"></div>
            <div class="shape-3"></div>
            <div class="shape-4"></div>
            <div class="shape-5"></div>
            <div class="shape-6"></div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                    <div class="section-header text-center">
                        <span class="section-sub-title">Школа футбола</span>
                        <h2 class="section-title">Этапы подготовки</h2>
                        <p>Чтобы стать футболистом, предстоит пройти длительный путь. Не ожидайте быстрых результатов. Запаситесь терпением и доверьтесь тренерам.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="testimonial-area bg_img" data-background="assets/images/elements/testimonial-single.jpg">
                        <div class="testimonial-slider">
                            <div class="testimonial-single">
                                <div class="testimonial-single__content">
                                    <h4 class="client-name">3-4 года</h4>
                                    <p>Формат обучения - подвижные игры с элементами футбола. Упражнения нацелены на развитие координации, гибкости, ловкости. Важно привить детям любовь к футболу. Помимо этого дети учатся правильному бегу и дыханию. </p>
                                </div>
                            </div><!-- testimonial-single end -->
                            <div class="testimonial-single">
                                <div class="testimonial-single__content">
                                    <h4 class="client-name">5-6 лет</h4>
                                    <p>Дети обучаются техническим приемам - ведению, остановке мяча, передачам. В этом возрасте все нападающие и хотят забивать голы. Результат на соревнованиях не имеет принципиального значения. Все дети после них награждаются медалями. </p>
                                </div>
                            </div>
                            <div class="testimonial-single">
                                <div class="testimonial-single__content">
                                    <h4 class="client-name">Младшая школа</h4>
                                    <p>Углубленное обучение техническим приемам. Детей учат тактике. Проходят первые соревнования с определением лучших команд и игроков. Очень важна самостоятельная тренировка детей дома или под присмотром родителей. </p>
                                </div>
                            </div>
                            <div class="testimonial-single">
                                <div class="testimonial-single__content">
                                    <h4 class="client-name">Средняя школа</h4>
                                    <p>Если ребенок прошел все предыдущие стадии и соответствует уровню по мастерству - он поступает в футбольную академию или в Училище Олимпийского резерва.  </p>
                                </div>
                            </div>
                            <!-- testimonial-single end -->
                        </div><!-- testimonial-slider end -->
                    </div><!-- testimonial-area end -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Testimonial"
    }
</script>

<style scoped>

</style>