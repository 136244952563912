<template>
    <section class="has-bg--shape pt-120 pb-120">
        <div class="bg-shape">
            <div class="round-shape d-sm-block d-none"><img src="assets/images/elements/round-shape.png" alt="image"></div>
            <div class="shape-1"></div>
            <div class="shape-2"></div>
            <div class="shape-3"></div>
            <div class="shape-4"></div>
            <div class="shape-5"></div>
            <div class="shape-6"></div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                    <div class="section-header text-center">
                        <span class="section-sub-title">Наша тренировка</span>
                        <h2 class="section-title">Этапы тренировки</h2>
                        <p>обычно состоит из нескольких этапов, которые направлены на всестороннее развитие ребёнка как спортсмена.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="testimonial-area bg_img" data-background="assets/images/elements/testimonial-single.jpg">
                        <div class="testimonial-slider">
                            <div class="testimonial-single">
                                <div class="testimonial-single__content">
                                    <h4 class="client-name">Разминка</h4>
                                    <p>Общая разминка включает лёгкий бег, прыжки, упражнения на растяжку и подвижность. Это помогает разогреть мышцы и подготовить тело к физической нагрузке.</p>
                                </div>
                            </div><!-- testimonial-single end -->
                            <div class="testimonial-single">
                                <div class="testimonial-single__content">
                                    <h4 class="client-name">Работа над техникой</h4>
                                    <p>Упражнения на ведение мяча разными частями стопы, изменение направления и скорости. Работа над точностью передачи мяча и его приёмом в движении. Отработка различных видов ударов по воротам, начиная с ближних и заканчивая дальними ударами.</p>
                                </div>
                            </div>
                            <div class="testimonial-single">
                                <div class="testimonial-single__content">
                                    <h4 class="client-name">Тактические упражнения</h4>
                                    <p>Отработка различных тактических схем, например, атака через центр, фланговые атаки, игра в обороне. Игры на ограниченном пространстве (3 на 3, 4 на 4) для развития командного взаимодействия и быстрого принятия решений. </p>
                                </div>
                            </div>
                            <div class="testimonial-single">
                                <div class="testimonial-single__content">
                                    <h4 class="client-name">Игровая практика</h4>
                                    <p>Полноценная игра между командами, чтобы дети могли применить на практике полученные знания и навыки.  В процессе игры тренер может останавливаться и давать комментарии, объясняя ошибки и предлагая варианты решений. </p>
                                </div>
                            </div>
                            <!-- testimonial-single end -->
                        </div><!-- testimonial-slider end -->
                    </div><!-- testimonial-area end -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "OurTraining"
    }
</script>

<style scoped>

</style>