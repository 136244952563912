import Vue from "vue";
import App from "./App.vue";
import { createProvider } from "./vue-apollo";
import Default from "./layouts/Default";
import router from "./router";
import "./styles/main.sass";
import "regenerator-runtime/runtime";

Vue.config.productionTip = false;

Vue.component("default-layout", Default);

new Vue({
  router,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
