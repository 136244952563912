<template>
  <header class="header">
    <div class="header__top">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="left d-flex align-items-center">
              <a href="tel:+79283198069"
                ><i class="las la-phone-volume"></i> Позвоните нам!</a
              >
            </div>
          </div>
          <div class="col-sm-6">
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- header__top end -->
    <div class="header__bottom">
      <div class="container">
        <nav class="navbar navbar-expand-xl p-0 align-items-center">
          <a class="site-logo site-title" href="/"
            ><img src="assets/images/logo.png" alt="site-logo" /><span
              class="logo-icon"
              ><i class="flaticon-fire"></i></span
          ></a>
          <button
            class="navbar-toggler ml-auto"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="menu-toggle"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="nav-right">
              <a href="#free" class="cmn-btn style--three btn--sm"
                ><img
                  src="assets/images/icon/btn/tag.png"
                  alt="icon"
                  class="mr-2"
                />
                Забери 3000 рублей</a
              >
            </div>
          </div>
        </nav>
      </div>
    </div>
    <!-- header__bottom end -->
  </header>
</template>

<script>
export default {
  name: "HeaderAkciya",
};
</script>

<style scoped></style>
